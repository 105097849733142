import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/451617388"
    bibleGroupSrc="https://www.docdroid.net/Cumd6N3/bible-group-homework-8-23-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Amos - Now What?" />
  </Layout>
)

export default SermonPost
